<template>
  <base-section id="404" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row justify="center">
        <v-col cols="10">
          <base-subheading
            :class="getClassTitle"
            :title="$t(keyTranslate.title)"
          />

          <base-subheading
            space="8"
            class="text-h6"
            :title="$t(keyTranslate.description)"
          />

          <base-subheading
            v-if="orderTimeDelay"
            space="8"
            class="text-h6"
            :title="`${$t('orderTimeDelay')}: ${orderTimeDelay} min`"
          />

          <base-subheading
            v-if="command"
            space="8"
            class="text-h6"
            :title="`${$t('orderNumber')} ${command}`"
          />

          <v-row justify="center">
            <v-col cols="12"><base-loading v-if="loading" /></v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="11">
                  <base-btn block :to="`/menu`">
                    {{ $t("returnToMenu") }}
                  </base-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import { orderCtrl } from "@/controllers";
import { orderStatusEnum } from "@/enums";
import { getErrorPayloadForSnackbar } from "@/helpers";

export default {
  name: "CheckPaymentStatusSection",

  provide: {
    heading: { align: "center" },
  },
  data() {
    return {
      loading: true,
      idInterval: null,
      command: null,
      orderStatus: "",
      orderTimeDelay: null,
    };
  },
  mounted() {
    const { requestId, orderStatus } = this.$route.params;
    if (!requestId || !orderStatus) return this.$router.replace("/menu");

    this.orderStatus = orderStatus;

    this.idInterval = setInterval(() => {
      orderCtrl
        .getOrderById(requestId)
        .then(({ status, command, order_time_delay }) => {
          this.orderStatus = status;

          if (
            status === orderStatusEnum.PREPAGO ||
            status === orderStatusEnum.BORRADOR
          )
            return;

          if (status === orderStatusEnum.SOLICITADO) {
            this.command = command;
            this.orderTimeDelay = order_time_delay;
          }
          this.loading = false;
          return clearInterval(this.idInterval);
        })
        .catch((error) => {
          const errorPayload = getErrorPayloadForSnackbar(error);
          this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
        });
    }, 5000);
  },
  beforeDestroy: function () {
    clearInterval(this.idInterval);
    this.$store.dispatch("cart/clearCart");
  },
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "100vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
    keyTranslate() {
      const setKey = {
        [orderStatusEnum.PREPAGO]: {
          title: "pendingPayment",
          description: "waitingConfirmPayment",
        },
        [orderStatusEnum.BORRADOR]: {
          title: "orderSent",
          description: "waitingConfirmRestaurant",
        },
        [orderStatusEnum.SOLICITADO]: {
          title: "orderAccepted",
          description: "orderAcceptedDescription",
        },
        [orderStatusEnum.CANCELADO]: {
          title: "orderRejected",
          description: "orderRejectedDescription",
        },
        [orderStatusEnum.INVALIDADO]: {
          title: "orderRejected",
          description: "orderRejectedByRestaurantDescription",
        },
      };
      return setKey[this.orderStatus] || { title: "", description: "" };
    },
    getClassTitle() {
      const orderRejected =
        this.orderStatus === orderStatusEnum.CANCELADO ||
        this.orderStatus === orderStatusEnum.INVALIDADO;
      return `${orderRejected ? "error" : "success"}--text`;
    },
  },
};
</script>
